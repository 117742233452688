import React from 'react';

import {
    IntroHolder,
    TwoColumns,
    Sidebar,
    Content,
    Amtsblatt,
} from '@bahaide/shared/components/InnerPage';

import InnerPage from '../components/InnerPage';

const AmtsblattPage = () => (
    <InnerPage title="Amtsblatt der Bahá’í-Gemeinde in Deutschland K.d.ö.R.">
        <IntroHolder
            title="Amtsblatt der Bahá’í-Gemeinde in Deutschland K.d.ö.R."
            subtitle="Bisher erschienene Ausgaben als PDF"
            breadcrumbs={{
                'current-item':
                    'Amtsblatt der Bahá’í-Gemeinde in Deutschland K.d.ö.R.',
            }}
        />

        <TwoColumns>
            <Content>
                <Amtsblatt
                    volume="12"
                    year="2024"
                    issue="1"
                    date="30. April 2024"
                    href="/dokumente/Amtsblatt-Bahai-Gemeinde-in-Deutschland-2024-1.pdf"
                >
                    Mitglieder des Nationalen Geistigen Rates der Bahá’í in Deutschland; Vertretungsorgan und Vertreter der Bahá’í-Gemeinde in Deutschland
                </Amtsblatt>
                <Amtsblatt
                    volume="11"
                    year="2023"
                    issue="2"
                    date="13. Dezember 2023"
                    href="/dokumente/Amtsblatt-Bahai-Gemeinde-in-Deutschland-2023-2.pdf"
                >
                    Gesetz zur Einführung eines Vertretungsregisters
                </Amtsblatt>
                <Amtsblatt
                    volume="11"
                    year="2023"
                    issue="1"
                    date="25. Mai 2023"
                    href="/dokumente/Amtsblatt-Bahai-Gemeinde-in-Deutschland-2023-1.pdf"
                >
                    Mitglieder des Nationalen Geistigen Rates der Bahá'í in Deutschland; Vertretungsorgan und Vertreter der Bahá'í-Gemeinde in Deutschland
                </Amtsblatt>
                <Amtsblatt
                    volume="10"
                    year="2022"
                    issue="2"
                    date="22. November 2022"
                    href="/dokumente/Amtsblatt-Bahai-Gemeinde-in-Deutschland-2022-2a.pdf"
                >
                    Vertretungsorgane und Vertreter der örtlichen Bahá'í-Gemeinden in Deutschland
                </Amtsblatt>
                <Amtsblatt
                    volume="10"
                    year="2022"
                    issue="1"
                    date="2. Juni 2022"
                    href="/dokumente/Amtsblatt-Bahai-Gemeinde-in-Deutschland-2022-1.pdf"
                >
                    Mitglieder des Nationalen Geistigen Rates der Bahá'í in Deutschland; Vertretungsorgan und Vertreter der Bahá'í-Gemeinde in Deutschland
                </Amtsblatt>
                <Amtsblatt
                    volume="9"
                    year="2021"
                    issue="2"
                    date="8. Dezember 2021"
                    href="/dokumente/Amtsblatt-Bahai-Gemeinde-in-Deutschland-2021-2b.pdf"
                >
                    Vertretungsorgane und Vertreter der örtlichen Bahá'í-Gemeinden in Deutschland
                </Amtsblatt>
                <Amtsblatt
                    volume="9"
                    year="2021"
                    issue="1"
                    date="27. April 2021"
                    href="/dokumente/Amtsblatt-Bahai-Gemeinde-in-Deutschland-2021-1.pdf"
                >
                    Mitglieder des Nationalen Geistigen Rates der Bahá'í in
                    Deutschland; Vertretungsorgan und Vertreter der
                    Bahá'í-Gemeinde in Deutschland
                </Amtsblatt>
                <Amtsblatt
                    volume="8"
                    year="2020"
                    issue="3"
                    date="4. Dezember 2020"
                    href="/dokumente/Amtsblatt-Bahai-Gemeinde-in-Deutschland-2020-3.pdf"
                >
                    Vertretungsorgane und Vertreter der örtlichen
                    Bahá'í-Gemeinden in Deutschland
                </Amtsblatt>
                <Amtsblatt
                    volume="8"
                    year="2020"
                    issue="2"
                    date="12. Mai 2020"
                    href="/dokumente/Amtsblatt-Bahai-Gemeinde-in-Deutschland-2020-2.pdf"
                >
                    Bahá'í-Feiertage in den Jahren 2020 - 2026
                </Amtsblatt>
                <Amtsblatt
                    volume="8"
                    year="2020"
                    issue="1"
                    date="5. Mai 2020"
                    href="/dokumente/Amtsblatt-Bahai-Gemeinde-in-Deutschland-2020-1.pdf"
                >
                    Mitglieder des Nationalen Geistigen Rates der Bahá'í in
                    Deutschland; Vertretungsorgan und Vertreter der
                    Bahá'í-Gemeinde in Deutschland
                </Amtsblatt>
                <Amtsblatt
                    volume="7"
                    year="2019"
                    issue="4"
                    date="4. Oktober 2019"
                    href="/dokumente/Amtsblatt-Bahai-Gemeinde-in-Deutschland-2019-4.pdf"
                >
                    Vertretungsorgane und Vertreter der örtlichen
                    Bahá'í-Gemeinden in Deutschland
                </Amtsblatt>
                <Amtsblatt
                    volume="7"
                    year="2019"
                    issue="3"
                    date="19. August 2019"
                    href="/dokumente/Amtsblatt-Bahai-Gemeinde-in-Deutschland-2019-3.pdf"
                >
                    Gesetz zur Änderung der Verfassung; Verfassung der
                    Bahá'í-Gemeinde in Deutschland vom 1. August 2019
                </Amtsblatt>
                <Amtsblatt
                    volume="7"
                    year="2019"
                    issue="2"
                    date="16. Mai 2019"
                    href="/dokumente/Amtsblatt-Bahai-Gemeinde-in-Deutschland-2019-2.pdf"
                >
                    Mitglieder des Nationalen Geistigen Rates der Bahá’í in
                    Deutschland; Vertretungsorgan und Vertreter der
                    Bahá’í-Gemeinde in Deutschland
                </Amtsblatt>

                <Amtsblatt
                    volume="7"
                    year="2019"
                    issue="1"
                    date="30. Januar 2019"
                    href="/dokumente/Amtsblatt-Bahai-Gemeinde-in-Deutschland-2019-1.pdf"
                >
                    Gesetz zur Eingliederung des örtlichen Geistigen Rates der
                    Bahá’í in Berlin-Pankow in die Bahá’í-Gemeinde in
                    Deutschland Körperschaft des öffentlichen Rechts vom 25.
                    Januar 2019; Vertretungsorgane und Vertreter örtlicher
                    Bahá’í-Gemeinden in Deutschland
                </Amtsblatt>

                <Amtsblatt
                    volume="6"
                    year="2018"
                    issue="2"
                    date="6. November 2018"
                    href="/dokumente/Amtsblatt-Bahai-Gemeinde-in-Deutschland-2018-2.pdf"
                >
                    Vertretungsorgane und Vertreter der örtlichen
                    Bahá’í-Gemeinden in Deutschland
                </Amtsblatt>

                <Amtsblatt
                    volume="6"
                    year="2018"
                    issue="1"
                    date="12. Juni 2018"
                    href="/dokumente/Amtsblatt-Bahai-Gemeinde-in-Deutschland-2018-1.pdf"
                >
                    Mitglieder des Nationalen Geistigen Rates der Bahá’í in
                    Deutschland; Vertretungsorgan und Vertreter der
                    Bahá’í-Gemeinde in Deutschland
                </Amtsblatt>

                <Amtsblatt
                    volume="5"
                    year="2017"
                    issue="4"
                    date="13. Dezember 2017"
                    href="/dokumente/Amtsblatt-Bahai-Gemeinde-in-Deutschland-2017-4.pdf"
                >
                    Gesetz zur Eingliederung örtlicher Geistiger Räte in die
                    Bahá’í-Gemeinde in Deutschland Körperschaft des öffentlichen
                    Rechts vom 10. Dezember 2017; Vertretungsorgane und
                    Vertreter der örtlichen Bahá’í-Gemeinden in Egelsbach,
                    Neu-Isenburg und Tübingen
                </Amtsblatt>

                <Amtsblatt
                    volume="5"
                    year="2017"
                    issue="3"
                    date="28. August 2017"
                    href="/dokumente/Amtsblatt-Bahai-Gemeinde-in-Deutschland-2017-3.pdf"
                >
                    Vertretungsorgane und Vertreter der örtlichen
                    Bahá’í-Gemeinden in Deutschland
                </Amtsblatt>

                <Amtsblatt
                    volume="5"
                    year="2017"
                    issue="2"
                    date="9. Mai 2017"
                    href="/dokumente/Amtsblatt-Bahai-Gemeinde-in-Deutschland-2017-2.pdf"
                >
                    Mitglieder des Nationalen Geistigen Rates der Bahá’í in
                    Deutschland; Vertretungsorgan und Vertreter der
                    Bahá’í-Gemeinde in Deutschland
                </Amtsblatt>

                <Amtsblatt
                    volume="5"
                    year="2017"
                    issue="1"
                    date="13. Januar 2017"
                    href="/dokumente/Amtsblatt-Bahai-Gemeinde-in-Deutschland-2017-1.pdf"
                >
                    Vertretungsorgane und Vertreter der örtlichen
                    Bahá’í-Gemeinden in Deutschland
                </Amtsblatt>

                <Amtsblatt
                    volume="4"
                    year="2016"
                    issue="4"
                    date="23. Dezember 2016 "
                    href="/dokumente/Amtsblatt-Bahai-Gemeinde-in-Deutschland-2016-4.pdf"
                >
                    Gesetz zur Eingliederung örtlicher Geistiger Räte in die
                    Bahá’í-Gemeinde in Deutschland Körperschaft des öffentlichen
                    Rechts
                </Amtsblatt>

                <Amtsblatt
                    volume="4"
                    year="2016"
                    issue="3"
                    date="11. Mai 2016"
                    href="/dokumente/Amtsblatt-Bahai-Gemeinde-in-Deutschland-2016-3.pdf"
                >
                    Mitglieder des Nationalen Geistigen Rates der Bahá’í in
                    Deutschland; Vertretungsorgan und Vertreter der
                    Bahá’í-Gemeinde in Deutschland
                </Amtsblatt>

                <Amtsblatt
                    volume="4"
                    year="2016"
                    issue="2"
                    date="26. April 2016"
                    href="/dokumente/Amtsblatt-Bahai-Gemeinde-in-Deutschland-2016-2.pdf"
                >
                    Gesetz zur Eingliederung des Geistigen Rates der Bahá’í in
                    Berlin-Charlottenburg-Wilmersdorf
                </Amtsblatt>

                <Amtsblatt
                    volume="4"
                    year="2016"
                    issue="1"
                    date="14. Januar 2016"
                    href="/dokumente/Amtsblatt-Bahai-Gemeinde-in-Deutschland-2016-1.pdf"
                >
                    Gesetz zur Regelung des finanziellen Geschäftsjahres
                </Amtsblatt>

                <Amtsblatt
                    volume="3"
                    year="2015"
                    issue="2"
                    date="29. Juli 2015"
                    href="/dokumente/Amtsblatt-Bahai-Gemeinde-in-Deutschland-2015-2.pdf"
                >
                    Bahá’í-Feiertage in den Jahren 2015 – 2020
                </Amtsblatt>

                <Amtsblatt
                    volume="3"
                    year="2015"
                    issue="1"
                    date="18. Mai 2015"
                    href="/dokumente/Amtsblatt-Bahai-Gemeinde-in-Deutschland-2015-1.pdf"
                >
                    Mitglieder des Nationalen Geistigen Rates der Bahá’í in
                    Deutschland; Vertretungsorgan und Vertreter der
                    Bahá’í-Gemeinde in Deutschland
                </Amtsblatt>

                <Amtsblatt
                    volume="2"
                    year="2014"
                    issue="2"
                    date="28. Oktober 2014"
                    href="/dokumente/Amtsblatt-Bahai-Gemeinde-in-Deutschland-2014-2.pdf"
                >
                    Bahá’í-Feiertage im Jahr 2015
                </Amtsblatt>

                <Amtsblatt
                    volume="2"
                    year="2014"
                    issue="1"
                    date="14. Mai 2014"
                    href="/dokumente/Amtsblatt-Bahai-Gemeinde-in-Deutschland-2014-1.pdf"
                >
                    Mitglieder des Nationalen Geistigen Rates der Bahá’í in
                    Deutschland; Vertretungsorgan und Vertreter der
                    Bahá’í-Gemeinde in Deutschland
                </Amtsblatt>

                <Amtsblatt
                    volume="1"
                    year="2013"
                    issue="3"
                    date="14. Oktober 2013"
                    href="/dokumente/Amtsblatt-Bahai-Gemeinde-in-Deutschland-2013-3.pdf"
                >
                    Beschluss zum Gesetzgebungsverfahren;
                    Unterwerfungserklärung; Eingliederungsgesetz
                </Amtsblatt>

                <Amtsblatt
                    volume="1"
                    year="2013"
                    issue="2"
                    date="28. Juni 2013"
                    href="/dokumente/Amtsblatt-Bahai-Gemeinde-in-Deutschland-2013-2.pdf"
                >
                    Mitglieder des Nationalen Geistigen Rates der Bahá’í in
                    Deutschland; Vertretungsorgan und Vertreter der
                    Bahá’í-Gemeinde in Deutschland
                </Amtsblatt>

                <Amtsblatt
                    volume="1"
                    year="2013"
                    issue="1"
                    date="17. April 2013"
                    href="/dokumente/Amtsblatt-Bahai-Gemeinde-in-Deutschland-2013-1.pdf"
                >
                    Verfassung der Bahá’í-Gemeinde in Deutschland;
                    Vertretungsorgan und Vertreter der Bahá’í-Gemeinde in
                    Deutschland; Mitglieder des Nationalen Geistigen Rates der
                    Bahá’í in Deutschland
                </Amtsblatt>
            </Content>
            <Sidebar />
        </TwoColumns>
    </InnerPage>
);

export default AmtsblattPage;
